/**
 *
 * user uuid
 */
var uuid=null;
var CacheProvider = (function(){
    function CacheProvider(){
        if(is_cache != ""  ){
            if(current_page == "home"){
             this.home();

            }else if(current_page == "courses"){
                this.courses();
            }else if(current_page == "paths"){
                this.paths();
            }else if(current_page == "path"){
                this.pathsStructure();
            }else if(current_page == "course"){
                this.courseStructure();
            }else if(current_page == "programming-kids"){
                this.courses();
            }else if(current_page == "bill"){
                this.bill();
            }else if(current_page == "register" || current_page == "invite" || current_page == "free-course"){
                this.register();
            }else if(current_page == "login"){
                this.login();
            }else if(current_page == "profile"){
                this.profile();
            }else if(current_page == "exercise"){
                this.exercise();
            }else if(current_page == "endOfCourse"){
                this.endCourse();
            }else if(current_page == "epay-bill"){
              get("cache/home",{},function(data){
                    avatarData(data);
                    token(data);
              });
            }else if(current_page == "school-course-create" ||
                    current_page == "school-course-view" ||
                    current_page == "add-group" ||
                    current_page == "contacts" || current_page == "about"){
                this.schoolCoursePages();
            }else if(current_page == "parents"){
                this.parents();
            }

        }else{
            /*for debug mode view all course*/
             $('.courseTile').show();
             $('.coursePathList').show();
        }


    }

    function showConfirmEmailModal(data){
        var today = new Date();


        if(localStorage.getItem("show_activate_modal_time") == null){

            if(data.confirm_email == false){
                var twentyMinutesLater = new Date();
                twentyMinutesLater.setMinutes(twentyMinutesLater.getMinutes() + 30);
                localStorage.setItem("show_activate_modal_time",twentyMinutesLater);
                $('#confirmEmailOverlay').show();
                $('.confirmAccount').show();

            }
        }
        if(localStorage.getItem("show_activate_modal_time") != null ){

            var showTime = new Date(localStorage.getItem("show_activate_modal_time"));

            if(today > showTime){
                if(data.confirm_email == false){
                    $('#confirmEmailOverlay').show();
                    $('.confirmAccount').show();
                    $('.closeConfirmAccount').hide();
                    $('.later30').hide();
                }
            }
        }
    }
    function showFunctionsStopping(data){
        if(data.stop_functions[0]){
            $('.stopFunctionalities').show();
            $('.stopFunctionalitiesOverlay').show();
            $('#hours-start').html(data.stop_functions[1]);
            $('#hours-finish').html(data.stop_functions[2]);
        }
    }
    var params = window.location.href.split("/");
    var id = params[params.length-1];

    function get(route, params,callback){
        params['registrationId'] = localStorage.registrationId;
        $.get(baseUrl+"/"+route, params )
        .done(function( data ) {
             data = JSON.parse(data);
             /**
              * set user uuid if is logged
              */
             if(typeof data.uuid != "undefined"){
                 uuid = data.uuid;
             }
             is_tester = data.is_tester;
             isSubscribe(data);
             showConfirmEmailModal(data);
             if(current_page != "exercise"){
                showPaymentMethods(data);
             }
             user_is_paid = data.user_is_paid;
             registrationId = data.registrationId;
             showGratisMessage(data);

             showFunctionsStopping(data);

             /*showActivationForCardUsers(data);*/

            $('#logout_token').val(data.token);

             
             if(data !== "")
             callback(data);
        });
    }
    function showGratisMessage(data){
        if(data.is_gratis){
            $('#gratis_message').slideDown();
        }
    }
    function showPaymentMethods(data){
      var payments = data.payments;
      if(typeof payments == "undefined"){
        return false;
      }
      if(payments[0].active == 1){
          $('#checkout').show();
      }else{
        $('#checkout').hide();
      }

      if(payments[1].active == 1){
          $('#epay_cards').show();
      }else{
        $('#epay_cards').hide();
      }
      if(payments[2].active == 1){
          $('#epay').show();
      }else{
          $('#epay').hide();
      }

      if(payments[3].active == 1){
          $('#easy_pay_office').show();
      }else{
          $('#easy_pay_office').hide();
      }

      if(payments[4].active == 1){
          $('#econt').show();
      }else{
          $('#econt').hide();
      }

      if(payments[5].active == 1){
          $('#bank_transfer').show();
      }else{
        $('#bank_transfer').hide();
      }

    }
   

    



    function assertPageIsLoaded(){
       var color = getStyleRuleValue('color', '.sandwich');

        if($('#username').css('color') == 'rgb(0, 153, 153)' && $('#testButton').css('color') == 'rgb(255, 255, 255)' && $('.sandwich').length != 0  && ($('.sandwich').css('color') == 'rgb(0, 153, 153)' || $('.sandwich').css('color')  == 'rgb(255, 255, 255)')){
          $('.caching-problem').addClass('inactive');
        }else{
            $('.caching-problem').removeClass('inactive');
          $.ajaxSetup({
             headers: {
                 'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
             }
          });
         $.post( baseUrl+ '/cache/recache', {publicId: public_id })
             .done(function() {
               window.location.reload();
             });
        }
    }

    function avatarData(data){
            user_role = data.role;
            is_user = data.is_user;
            if(is_user){
                var name;
                var firstName;
                if(data.username){
                    name = (data.username).split(' ');
                    firstName = name[0];
                }else{
                    firstName = data.email;
                }
                $('#username .textColor').empty();
                if(data.role == "student"){
                    $('.userStatus').html(trans("langPage.roleStudent"));
                    $('#toNewCourse').remove();
                }else if(data.role == "teacher"){
                    $('.userStatus').html(trans("langPage.roleTeacher"));

                }
                $('#username .textColor').html(firstName);

                if(data.avatar == false){
                    $('#user_icons').empty();
                    $('#user_icons').html('<span class="fa fa-user"></span>');
                }else{
                    $('#user_icons').html('<img id="user_avatar" src="'+data.avatar+'" class="avatarTop" alt="avatar">');
                }
                $('#toLogin').remove();
                $('#toRegister').remove();

            }
    }


    function profileAvatar(data){
         if(is_user){
            if(data.avatar == false){
                   $('#uploadAvatar').empty();
                   $('#uploadAvatar').html('<div class="fa fa-user" id="user_profile_icon"></div>');
            }else{
                   $('#uploadAvatar').html('<img id="profilePicture" src="'+data.avatar+'" width="80px;" height="auto" style="width:80px; height:80px;">');
            }
            if(data.username != null){
                $('.prof_text').find('.bolder').html(data.username);
            }else{
                $('.prof_text').find('.bolder').html(data.email);
            }

            if((data.role) == "student"){
                $('.prof_text h3').html(trans("langPage.roleStudent"));
            }else if((data.role)=="teacher"){
                $('.prof_text h3').html(trans("langPage.roleTeacher"));
            }

         }

    }
    function token(data){
       $("input[name='_token']").val(data["token"]);
        $("meta[name='csrf-token']").attr("content",data["token"]);

    }
    function errorMsg(data){
        if(data["captcha"] != ""){
            $('body').append('<script type="text/javascript" src="https://www.google.com/recaptcha/api.js?hl='+locale+'"></script>');
            $('#capcha-group').empty();
            $('#capcha-group').prepend(data["captcha"]);
        }else{
            $('#capcha-group').empty();
        }
        if(data.msg != null){
            $('.errorMsg').find('ul').append('<li>'+data.msg+'</li>');
           /* $('#capcha-group').append();*/
        }
        if(data.error != null){
             $('.errorMsg').find('ul').append('<li>'+data.error+'</li>');
        }
        if(data.errors != null){
             $('.errorMsg').find('ul').append('<li>'+data.errors["msg"]+'</li>');
        }
    }

    function errorMsgRegister(data){
        if(data["captcha"] != ""){
            $('body').append('<script type="text/javascript" src="https://www.google.com/recaptcha/api.js?hl='+locale+'"></script>');
            $('#capcha-group').empty();
            $('#capcha-group').prepend(data["captcha"]);
        }else{
            $('#capcha-group').empty();
        }
        if(data.msg != null){
            $('.errorMsg').find('ul').append('<li>'+data.msg+'</li>');
            
            if(typeof grecaptcha != "undefined"){
                grecaptcha.render(
            "capcha-group",
                {sitekey:"6LfyGiEUAAAAAGpNv6bk4bLdqGEeDhmLdMchQxWj"}
            );
            }

        }

        if(typeof data.invite_code != "undefined" && data.invite_code != null){
            IviteOnly.toggleForm(false);
            $('#inviteNumber').val(data.invite_code);
            $('.codeRegistration').hide();
        }

        if(data["error"] != null){
            if(data["error"].email != null){
                $('.errorMsg').find('ul').append('<li>'+data["error"].email[0]+'</li>');
            }
            if(data["error"].password != null){
                $('.errorMsg').find('ul').append('<li>'+data["error"].password[0]+'</li>');
            }
            if(invite_only == 1){

                $('.codeRegistration').hide();
                IviteOnly.toggleForm(false);
            }
        }
    }
   
    function avatarInEx(data){
        var name;
        var firstName;
        if(data.username){
            name = (data.username).split(' ');
            firstName = name[0];
        }else{
            firstName = data.email;
        }
        $('#username .textColor').empty();
        if(data.role == "student"){
            $('.userStatus').html("ученик");
            $('#toNewCourse').remove();
        }else if(data.role == "teacher"){
            $('.userStatus').html("учител");

        }
        $('#username .textColor').html(firstName);
       /* if(data.username != null){

        }else{
             $('#username .textColor').html(data.email);
        }*/
        if(data.avatar == false){
            $('#user_icons').empty();
            $('#user_icons').html('<span class="fa fa-user"></span>');
        }else{
            $('#user_icons').html('<img id="user_avatar" src="'+data.avatar+'" class="avatarTop" alt="avatar">');
        }
    }

     function exHeader(data){
        if(isJunior){
            $('.myMoney').html(data.wallet);
            $('.myRuby').html(data.rubies);
            //$('.exN').empty();
            //$('.ex')
        }

        $('#logout_token').val(data.token);

    }

    function firstEnterEx(data){

        first_enter_course = JSON.parse(data.first_enter_course);
        if(!first_enter_course){
            $('.onboardingExplanation').hide();
            $('.ex_hand').hide();
            $('.popupOnboarding').hide();
            $('.mobilePopupOnboarding').hide();
            $('.popupOnboardingCode').hide();
            $('.popupOnboardingStaticCode').hide();
            $('.popupOnboardingStatic').hide();
            $('.infoHelpBox').hide();
            $('.circle-onboarding-parent').hide();
            $('#overlayInfoHelpBox').hide();
            $('#editorCont').css({zIndex:'auto'});
            setTimeout(function(){
                $('#overlayInfoHelpBox').hide();
            },1200);
            $('canvas.highlight').hide();
        }

    }
    function assignVariables(data){
        firstEnter = data.firstEnter;
        userRole = data.role;
        experience = data.experience;
        afterRules = data.afterRule, runtimeRules = data.runtimeRule, afterTestRules = data.afterTestRule;
        afterRules = JSON.parse(afterRules);
        runtimeRules = JSON.parse(runtimeRules);
        afterTestRules = JSON.parse(afterTestRules);
        isLastCourse = data.last_course_ex,isLastModule = data.last;
       /* isRelatedWithMasteryLocal = data.has_relation;
        is_emh = data.is_emh;*/
        currentLevel = data.level;
        userId = data.user_id;
        coinWallet = data.wallet;

        countOfUsHints = data.countOfHints;
        nextExerciseId = data.nextExId;
        nextFinishedExId = data.next_finished_ex_id;
        nextFinishedExPublicId = data.next_finished_ex_public_id;
        nextExFromCourseId = data.next_ex_id;
        nextExFromCoursePublicId = data.next_ex_public_id;
        isFinishedBefore =data.is_finished_before;
        lastCourseExerciseId = data.last_course_ex;

    }

    function setExToken(data){
        $('meta[name="csrf-token"]').attr('content',data.token);
    }
    function userCode(data){
        if(data.restriction != null){

              if((data.course_for_teachers == 0 && data.is_teacher_tester == 0) || (data.course_for_teachers == 0 && data.is_teacher_tester == 1) || data.is_school == 0){
                  $('#restriction_window').show();
              }
        }

        
            if(isFinishedBefore == 1){
                /*exerciseFuncJS = decodeURI(data.code);
                exerciseFuncCSS = decodeURI(data.css_code);
                exerciseFuncHTML = decodeURI(data.html_code);*/
                
                $('#testButtonPlaceholder').hide();
            }
        
    }
    function isFinished(data){
        if(data.is_finished_before === 1){
            
            $('#testButton').css('display', 'block');
            $("#continueButton").css('display', 'block');
            if(mobile){
                $('#mobActionButton').show();
                $('#mobActionButton span')
                                .removeClass('fa-ellipsis-h').addClass('fa-angle-double-right');
                $('#mobActionButton').css('background-color','#099');
                $('#mobActionButton').unbind( "click" );
                $('#mobActionButton').bind('click', function(){
                    workFuncForExPractise.doActions();
                });
            }
            
            $('#continueButtonPlaceholder').hide();
            $("#seeAgainBtn").removeClass("inactive");
        }else if(data.is_finished_before === 0){
            /*$('#testButton').css('display', 'none');*/
            $("#continueButton").css('display', 'none');
            $('#continueButtonPlaceholder').show();
            $("#seeAgainBtn").addClass("inactive");
        }

    }
    var finished_exercises = [];
    var next_exercise = null;
    function courseNavigation(data){
         finished_exercises = data.finished_exercises;
         next_exercise = data.activeEx;
        var apprentice = $('.nav_ex_title');
        for(var i =0;i<apprentice.length;i++){
            var id = $(apprentice[i]).attr("id");
            var is_finish = checkIsFinished(id);
            if(is_finish != false){
                $('#'+id).find('a').attr("href",baseUrl+"/exercise/view/"+is_finish)
                 $('#'+id).find('a').attr('onclick', '');
                $('#'+id).addClass('finishedSelector');
            }else if(next_exercise != null && (next_exercise.id==id || (next_exercise.number == "first" && id==next_exercise.id))){
                $('#'+id).find('a').attr("href",baseUrl+"/exercise/view/"+next_exercise.public_id)
                $('#'+id).find('a').attr('onclick', '');
                $('#'+id).addClass('finishedSelector');
            }else{
                $('#'+id).removeClass('finishedSelector');
                 $('#'+id).find('a').attr('href','#');
                 $('#'+id).find('a').attr('onclick','showMessage(this)');

            }
        }
        //
    }
    function checkIsFinished(id){
        for(var i =0;i<finished_exercises.length;i++){
            if(id==finished_exercises[i].apprenticeship_id && finished_exercises[i].finished == 1){
                return finished_exercises[i].public_id;
            }
        }
        return false;
    }
    function mobileView(data){
        if(data.isMobile == 1){
            mobile = true;
        }else{
            mobile = false;
        }
        if(mobile){
            $('.mobExToggleMenu').show();
        }else {
            $('.mobExToggleMenu').hide();
        }
    }
    
    function findActiveAprrentice(active){
        if(active.number == "first"){
            return active.ex_id;
        }else{
            return active.number;
        }

    }
    function percentOfCourseByModule(modules){
        var percent = 0;
        for(var i in modules){
            percent += modules[i];
        }
        var len = Object.keys(modules).length;
        return Math.round(percent/len);
    }

    function showThirdStepPayment(data){

        if(data.show_payment_modal == true || data.show_modal_log == true){
            payment_method = data.payment_method;
            $('.paymentsCards').addClass("inactive");
            $('.econtDataPayment ').addClass("inactive");
            $('#bankCashTransfer').addClass("inactive");
            $('#modalStartTrial').removeClass('inactive');
            $('.continuePlan').attr("class",
            "button prColBG accColBGHoverSame tileShadowPage continuePlan   payment_last_step ");
            $('.payment_steps_global .step-1').removeClass('activeStep').addClass('finishedStep').find('.payment_step_number span').addClass('fa fa-check').html('');
            $('.payment_steps_global .step-2').removeClass('activeStep').addClass('finishedStep').find('.payment_step_number span').addClass('fa fa-check').html('');
            $('.payment_steps_global .step-3').addClass('activeStep');
            $('.continuePlan').addClass(data.payment_method);
            continuePaymentsData();
            $('.continuePlan').removeClass('premium_plan');
            $('#startTrialOverlay').removeClass('inactive');
            $('.firstStep').addClass('inactive');
            getOffices();
            $('.secondStepPremium').addClass('inactive');
        }else if(data['error-subs'] != null) {
            $('#modalStartTrial').removeClass('inactive');
            $('#startTrialOverlay').removeClass('inactive');
            $('.secondStepPremium').removeClass('inactive');
            $('.firstStep').addClass('inactive');
            $('.choosePlan').addClass('inactive');
           /* $('#emailPayFromHome').after("<p style='color:red;'>"+data['error-subs']+"</p>");*/
        }else{
            $('#modalStartTrial').addClass('inactive');
            $('#startTrialOverlay').addClass('inactive');
            $('.firstStep').removeClass('inactive');
            $('.continuePlan').removeClass('premium_plan');
            $('.thirdStepPremium').addClass('inactive');
        }
        
        if(data.show_login_reg_err == true){
            playNow();
            choosepaymentPlan();
        }
    }

    function parentsPage(data){

        if(is_user){
            var name;
            var firstName;
            if(data.username){
                name = (data.username).split(' ');
                firstName = name[0];
            }else{
                firstName = data.email;
            }
            $('#username .textColor').empty();
            if(data.role == "student"){
                $('.userStatus').html(trans("langPage.roleStudent"));
                $('#toNewCourse').remove();
            }else if(data.role == "teacher"){
                $('.userStatus').html(trans("langPage.roleTeacher"));

            }
            $('#username .textColor').html(firstName);

            if(data.avatar == false){
                $('#user_icons').empty();
                $('#user_icons').html('<span class="fa fa-user"></span>');
            }else{
                $('#user_icons').html('<img id="user_avatar" src="'+data.avatar+'" class="avatarTop" alt="avatar">');
            }
            $('#toLogin').remove();
            $('#toRegister').remove();

        }
    }

    
   
    
    CacheProvider.prototype.home = function(){
        get("cache/home",{},function(data){


            avatarData(data);

            token(data);
            
            showThirdStepPayment(data);

            showDiscountDialog(data);
            console.log(params);
           
            
        });
    };
    CacheProvider.prototype.courses = function(){
        get("cache/courses",{},function(data){

            avatarData(data);


        });
    };
    CacheProvider.prototype.courses = function(){
        get("cache/programiranezadeca",{},function(data){

            avatarData(data);


        });
    };
    CacheProvider.prototype.paths = function(){
        get("cache/paths",{},function(data){
            avatarData(data);
            coursesPercent(data);
        });
    };
     CacheProvider.prototype.pathsStructure = function(){
        get("cache/path/"+id,{},function(data){
            avatarData(data);
            showUserSchoolCourse(data.school_courses,data.role);
            progressInPaths(data);
            coursesPercentInPaths(data);
            /*courseStructure(data);*/
        });
    };
    CacheProvider.prototype.courseStructure = function(){
        get("cache/course/"+id,{},function(data){
            avatarData(data);
        });
    };
    CacheProvider.prototype.bill = function(){
        get("cache/bill",{},function(data){
             avatarData(data);
             token(data);
             checkBillUrl();
             errorMsgInHome(data);
            showThirdStepPayment(data);
            showDiscountDialog(data);
        });
    };
    CacheProvider.prototype.register = function(){
        get("cache/auth/register",{},function(data){
            token(data);
            errorMsgRegister(data);

        });
    };
     CacheProvider.prototype.login = function(){
        get("cache/auth/login",{},function(data){
            token(data);
            errorMsg(data);
        });
    };
    CacheProvider.prototype.profile = function(){
        get("cache/profile",{},function(data){
            avatarData(data);
            profileAvatar(data);
            token(data);
        });
    };
    function isSubscribe(data){
        if(typeof data.is_subscribe != "undefined" && data.is_subscribe != null){
         $('.subscribeWrapper').find('.justifyFlexAround').text("Ти вече се абонира за бюлетина!").css('color','#777');
        }else if(typeof data.is_subscribe != "undefined" &&
                typeof data.is_subscribe_queue != "undefined" && data.is_subscribe == null && data.is_subscribe_queue == "1"){
             $('.subscribeWrapper')
                     .find('.justifyFlexAround')
                     .text("Изпратихме ви и-мейл за потвърждение.")
                     .css('color','#777');
        }

    }
    CacheProvider.prototype.exercise = function(){
        get("exercise/params/" +id ,{},function(data){
            /*gameLoaded();*/
            if(data.is_access === false){
                window.location.href = baseUrl;
            }
            redirectIfBonusNotExist(data);
            avatarInEx(data);

            assignVariables(data);
            exHeader(data);
            userCode(data);
            isFinished(data);
            setExToken(data);
            courseNavigation(data);
            mobileView(data);
            
            firstEnterEx(data);
            
            if(!isJunior){
                if(!mobile){
                    /*assertPageIsLoaded();*/
                }
            }
            //render iframe
           
            

            

            if(!isJunior){
                showDarkTheme(data);
            }

        });
    };
    function showDarkTheme(data){
        changeEditorTheme = data.color;
        if(data.color == -1){
            $.get(baseUrl + "/css/themeDark.css", function (data) {
                $('head').append('<style id="themeDark" class="darkTheme">' + data + '</style>');
            });
        }else{
             $('.darkTheme').remove();
        }
       
    }
    function redirectIfBonusNotExist(data){
        if(typeof data.is_bonus != "undefined"  && data.is_bonus != null){
           // window.location.href=baseUrl+'/profile/show?bonus_msg='+data.is_bonus;
        }
        is_paid = data.is_payment;
        if(typeof data.show_share_modal != "undefined" && data.show_share_modal == true){
            showPromotionDialog();
        }
        if(typeof data.show_end_free_course != "undefined" && data.show_end_free_course < exerciseId){
           $('#promotion_overlay').remove();
            $('#promotion_modal').remove();
        }else if(typeof data.url_share != "undefined" && data.url_share == 1 && data.show_end_free_course != exerciseId){
            $('#promotion_overlay').remove();
            $('#promotion_modal').remove();
        }

    }
    CacheProvider.prototype.endCourse = function(){
        get("/cache/endCourse" ,{id:courseId},function(data){
            avatarData(data);

            if(data.certificate != null){
                $('#certificate').html("<a class='certificate_placeholder_color' onclick='Certificate.getHTML("+data.certificate+")'></a>");
            }else{
                $('#certificate').html("Все още не можеш да получиш своя сертификат!");
            }
        });
    };
     CacheProvider.prototype.epayBill = function(){
        get("/cache/bill" ,[],function(data){
            avatarData(data);

        });
    };
    CacheProvider.prototype.schoolCoursePages = function(){
        get("/cache/schoolCoursePages" ,[],function(data){
            avatarData(data);

        });
    };
    CacheProvider.prototype.parents = function(){
        get("/cache/parents",[],function(data){
            parentsPage(data);
            showDiscountDialog(data);
        });
    }

    function showUserSchoolCourse(data,role){
        for(var i in data){
            $('.courseTile'+data[i].course_id).show();

            $('.courseList'+data[i].course_id).show();
            if(current_page == "path"){
                 $('#course-'+data[i].course_id).show();
            }
        }
        if(role == "admin"){
            $('.courseTile').show();
            $('.coursePathList').show();
        }
    }

    function showDiscountDialog(data){
        amount_discount = data['amount'];
        half_amount_discount = data["half_amount_discount"];
        console.log(amount_discount);
        if(data.discount == true){
            addPromotion();
            if(data.share_promotion == 1 && data.discount_percent == 0){

            }else{
                if(data.discount_percent == 10 || data.discount_percent == 100){
                    $('.discount-window').removeClass("inactive");
                }
                $('.payment_plan_half').find('.smallPlan').html('<span class="price-underlined price-darker">'+half_amount+'лв. </span> ' + half_amount_discount + 'лв.');
                $('.payment_plan_premium').find('.smallPlan').html('<span class="price-underlined price-darker">'+amount+'лв. </span> ' + amount_discount + 'лв.');
                if(data.discount_percent == 0){
                    $('.d_percent').html(5);
                }else{
                    /*$('.d_percent').html(data.discount_percent);*/
                    if(data.discount_percent == 10){
                        $('.d_percent').html(20);
                    }else if(data.discount_percent == 100){
                        $('.d_percent').html(amount-amount_discount);
                    }
                    
                }

                $('.promotion-price span').html(amount_discount);
            }
        }

        if(data.share_url == 1){
             $('.share-left').find('.checkmark-circle').css({display:'inline-block'});
        }
    }
    function clickTab2(allTabClass, activeClass, inactiveClass, el, allDivClass) {
        var id = $(el).attr("id");
        id = id.replace("_Tab", "");

        $(el).removeClass(inactiveClass).addClass(activeClass);
        $(allTabClass).not(el).removeClass(activeClass).addClass(inactiveClass);

        $("#" + id).removeClass('inactive');

        $(allDivClass).not("#" + id).addClass('inactive');
    }
    function getStyle(){
      console.log("get style");
    }
    function getStyleRuleValue(style, selector, sheet) {
        var sheets = typeof sheet !== 'undefined' ? [sheet] : document.styleSheets;
        for (var i = 0, l = sheets.length; i < l; i++) {
            var sheet = sheets[i];
            if( !sheet.cssRules ) { continue; }
            for (var j = 0, k = sheet.cssRules.length; j < k; j++) {
                var rule = sheet.cssRules[j];
                if (rule.selectorText && rule.selectorText.split(',').indexOf(selector) !== -1) {
                    return rule.style[style];
                }
            }
        }
        return null;
    }
    return CacheProvider;
})();
var cp = new CacheProvider();
